import axios from "axios";
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import {
  GetLocalStorageUser,
  GetLongitudeLatitude,
} from "../GlobalUtils/GetLocalStorageValues";

const Location = GetLocalStorageUser("location");
export let apiToken = JSON.parse(localStorage.getItem("user_profile"));
GetLongitudeLatitude();

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer " + apiToken?.accessToken,
    "PARTNERID": "ACCESSBANKSERIEALONE",
    SOURCE: "WEB-SUPERDEALER-PORTAL",
    LONGITUDE: Location?.longitude,
    LATITUDE: Location?.latitude,
  },
});

export const formApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + apiToken?.accessToken,
  },
});

export const invoiceApi = axios.create({
  baseURL: `https://invdevapi.ucard.store/api/`,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + apiToken?.accessToken,
  },
});

export const formApiInvoice = axios.create({
  baseURL: `https://invdevapi.ucard.store/api/`,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + apiToken?.accessToken,
  },
});

api?.interceptors?.request?.use(
  async function (req) {
    if (!apiToken) {
      apiToken = localStorage.getItem("user_profile")
        ? JSON.parse(localStorage.getItem("user_profile"))
        : null;
      req.headers.Authorization = `Bearer ${apiToken?.accessToken}`;
    }

    let user = jwtDecode(apiToken?.accessToken);
    let isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 1;

    if (isExpired) {
      localStorage.removeItem("user_profile");
      localStorage.removeItem("user");
      window.location.replace("/");
    }

    return req;
  }
);

export default api;
