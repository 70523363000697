import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { UsersService } from "./UsersService";

//Agents
export const SuperdealerAgents = createAsyncThunk(
  "ls/SuperdealerAgents",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await UsersService.usersModule().SuperdealerAgents(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const SingleSuperdealerAgents = createAsyncThunk(
  "ls/SingleSuperdealerAgents",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await UsersService.usersModule().SingleSuperdealerAgents(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return error?.response;
    }
  }
);

export const UpdateSuperdealerAgent = createAsyncThunk(
  "ls/UpdateSuperdealerAgent",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await UsersService.usersModule().UpdateSuperdealerAgents(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetSuperdealerAgentProduct = createAsyncThunk(
  "ls/GetSuperdealerAgentProduct",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await UsersService.usersModule().GetSuperdealerAgentsProducts(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

//Resellers

export const SuperdealerResellers = createAsyncThunk(
  "ls/SuperdealerResellers",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await UsersService.usersModule().SuperdealerResellers(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const SingleSuperdealerResellers = createAsyncThunk(
  "ls/SingleSuperdealerResellers",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await UsersService.usersModule().SingleSuperdealerResellers(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const UpdateSuperdealerReseller = createAsyncThunk(
  "ls/UpdateSuperdealerReseller",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await UsersService.usersModule().UpdateSuperdealerReseller(payload);
      toast.success("Profile updated successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      toast.error("Profile update failed");
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetSuperdealerResellerProducts = createAsyncThunk(
  "ls/GetSuperdealerResellerProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await UsersService.usersModule().GetSuperdealerResellerProducts(
          payload
        );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ListCustomers = createAsyncThunk(
  "ls/GetSuperdealerResellerProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await UsersService.usersModule().ListCustomers(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ListTellers = createAsyncThunk(
  "ls/GetSuperdealerResellerProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await UsersService.usersModule().ListTellers(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ListAgents = createAsyncThunk(
  "ls/GetSuperdealerResellerProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await UsersService.usersModule().ListAgents(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ListCustomersWallets = createAsyncThunk(
  "ls/ListCustomersWallets",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await UsersService.usersModule().ListCustomerWallets(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const BulkUploadAgent = createAsyncThunk(
  "ls/AddContactPerson",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await UsersService.usersModule().BulkUploadAgent(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const AgentSearch = createAsyncThunk(
  "ls/AddContactPerson",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await UsersService.usersModule().AgentSearch(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const getAllProducts = createAsyncThunk(
  "ls/getAllProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await UsersService.usersModule().getProducts(value);
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const OnboardAgent = createAsyncThunk(
  "ls/OnboardAgent",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await UsersService.usersModule().OnboardAgent(value);
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

// OnboardTeller
export const OnboardTeller = createAsyncThunk(
  "ls/OnboardTeller",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await UsersService.usersModule().OnboardTeller(value);
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const ActivateCustomers = createAsyncThunk(
  "ls/ActivateCustomers",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await UsersService.usersModule().ActivateCustomers(
        value
      );
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const DeactivateCustomers = createAsyncThunk(
  "ls/DeactivateCustomers",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await UsersService.usersModule().DeactivateCustomers(
        value
      );
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

// AddMoreProducts
export const AddMoreProducts = createAsyncThunk(
  "ls/AddMoreProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await UsersService.usersModule().AddMoreProducts(value);
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

// CustomerProducts
export const CustomerProductsAgent = createAsyncThunk(
  "ls/CustomerProductsAgent",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await UsersService.usersModule().CustomerProductsAgent(
        value
      );
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

// CustomerProducts
export const CustomerProductsResseller = createAsyncThunk(
  "ls/CustomerProductsResseller",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response =
        await UsersService.usersModule().CustomerProductsResseller(value);
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

// CustomerProducts
export const CustomerProductsRessellerAndAgent = createAsyncThunk(
  "ls/CustomerProductsRessellerAndAgent",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response =
        await UsersService.usersModule().CustomerProductsRessellerAndAgent(
          value
        );
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

// UpdateCustomerProducts
export const UpdateCustomerProducts = createAsyncThunk(
  "ls/UpdateCustomerProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await UsersService.usersModule().UpdateCustomerProducts(
        value
      );
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

// UpdateCustomerCommission
export const UpdateCustomerCommission = createAsyncThunk(
  "ls/UpdateCustomerCommission",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response =
        await UsersService.usersModule().UpdateCustomerCommission(value);
      // await dispatch(CustomerProductsResseller(value?.agentId));
      toast.success(response?.payload?.data?.message);
      return response.data;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

// UpdateCommission
export const UpdateCommission = createAsyncThunk(
  "ls/UpdateCommission",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await UsersService.usersModule().UpdateCommission(value);
      return response.data;
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  }
);

export const EnableAction = createAsyncThunk(
  "ls/EnableAction",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await UsersService.usersModule().EnableAction(value);
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const DisableAction = createAsyncThunk(
  "ls/DisableAction",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await UsersService.usersModule().DisableAction(value);
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const UsersSlice = createSlice({
  name: "Users",
  initialState: "",
  extraReducers: {
    [DisableAction.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [DisableAction.fulfilled]: (state, action) => {
      return { ...state, DisableAction: action.payload, loading: false };
    },
    [DisableAction.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [EnableAction.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [EnableAction.fulfilled]: (state, action) => {
      return { ...state, EnableAction: action.payload, loading: false };
    },
    [EnableAction.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [UpdateCustomerCommission.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [UpdateCustomerCommission.fulfilled]: (state, action) => {
      return {
        ...state,
        UpdateCustomerCommission: action.payload,
        loading: false,
      };
    },
    [UpdateCustomerCommission.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [UpdateCommission.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [UpdateCommission.fulfilled]: (state, action) => {
      return {
        ...state,
        UpdateCommission: action.payload,
        loading: false,
      };
    },
    [UpdateCommission.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [UpdateCustomerProducts.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [UpdateCustomerProducts.fulfilled]: (state, action) => {
      return {
        ...state,
        UpdateCustomerProducts: action.payload,
        loading: false,
      };
    },
    [UpdateCustomerProducts.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [CustomerProductsAgent.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CustomerProductsAgent.fulfilled]: (state, action) => {
      return { ...state, CustomerProducts: action.payload, loading: false };
    },
    [CustomerProductsAgent.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [CustomerProductsResseller.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CustomerProductsResseller.fulfilled]: (state, action) => {
      return { ...state, CustomerProducts: action.payload, loading: false };
    },
    [CustomerProductsResseller.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    // CustomerProductsRessellerAndAgent
    [CustomerProductsRessellerAndAgent.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CustomerProductsRessellerAndAgent.fulfilled]: (state, action) => {
      return { ...state, CustomerProducts: action.payload, loading: false };
    },
    [CustomerProductsRessellerAndAgent.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AddMoreProducts.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AddMoreProducts.fulfilled]: (state, action) => {
      return { ...state, AddMoreProducts: action.payload, loading: false };
    },
    [AddMoreProducts.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [DeactivateCustomers.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [DeactivateCustomers.fulfilled]: (state, action) => {
      return { ...state, DeactivateCustomers: action.payload, loading: false };
    },
    [DeactivateCustomers.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActivateCustomers.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ActivateCustomers.fulfilled]: (state, action) => {
      return { ...state, ActivateCustomers: action.payload, loading: false };
    },
    [ActivateCustomers.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ListCustomers.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ListCustomers.fulfilled]: (state, action) => {
      return { ...state, ListAllCustomers: action.payload, loading: false };
    },
    [ListCustomers.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    // ListTellers
    [ListTellers.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ListTellers.fulfilled]: (state, action) => {
      return { ...state, ListAllTellers: action.payload, loading: false };
    },
    [ListTellers.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [OnboardAgent.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [OnboardAgent.fulfilled]: (state, action) => {
      return { ...state, OnboardAgents: action.payload, loading: false };
    },
    [OnboardAgent.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    // OnboardTeller
    [OnboardTeller.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [OnboardTeller.fulfilled]: (state, action) => {
      return { ...state, OnboardTellers: action.payload, loading: false };
    },
    [OnboardTeller.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [getAllProducts.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [getAllProducts.fulfilled]: (state, action) => {
      return { ...state, Products: action.payload, loading: false };
    },
    [getAllProducts.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AgentSearch.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AgentSearch.fulfilled]: (state, action) => {
      return { ...state, AgentVerify: action.payload, loading: false };
    },
    [AgentSearch.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [BulkUploadAgent.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [BulkUploadAgent.fulfilled]: (state, action) => {
      return { ...state, BulkUploadAgents: action.payload, loading: false };
    },
    [BulkUploadAgent.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    //Agents
    [SuperdealerAgents.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [SuperdealerAgents.fulfilled]: (state, action) => {
      return { ...state, superdealerAgents: action.payload, loading: false };
    },
    [SuperdealerAgents.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [SingleSuperdealerAgents.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [SingleSuperdealerAgents.fulfilled]: (state, action) => {
      return { ...state, agent: action.payload, loading: false };
    },
    [SingleSuperdealerAgents.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [UpdateSuperdealerAgent.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [UpdateSuperdealerAgent.fulfilled]: (state, action) => {
      return { ...state, updatedAgent: action.payload, loading: false };
    },
    [UpdateSuperdealerAgent.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [GetSuperdealerAgentProduct.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetSuperdealerAgentProduct.fulfilled]: (state, action) => {
      return { ...state, agentProduct: action.payload, loading: false };
    },
    [GetSuperdealerAgentProduct.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    //Resellers

    [SuperdealerResellers.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    [SuperdealerResellers.fulfilled]: (state, action) => {
      return { ...state, superdealerResellers: action.payload, loading: false };
    },
    [SuperdealerResellers.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [SingleSuperdealerResellers.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [SingleSuperdealerResellers.fulfilled]: (state, action) => {
      return { ...state, reseller: action.payload, loading: false };
    },
    [SingleSuperdealerResellers.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [UpdateSuperdealerReseller.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [UpdateSuperdealerReseller.fulfilled]: (state, action) => {
      return { ...state, updatedReseller: action.payload, loading: false };
    },
    [UpdateSuperdealerReseller.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [GetSuperdealerResellerProducts.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetSuperdealerResellerProducts.fulfilled]: (state, action) => {
      return { ...state, resellerProducts: action.payload, loading: false };
    },
    [GetSuperdealerResellerProducts.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ListCustomersWallets.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ListCustomersWallets.fulfilled]: (state, action) => {
      return { ...state, customerWallets: action.payload, loading: false };
    },
    [ListCustomersWallets.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export default UsersSlice.reducer;
