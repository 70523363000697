import api from "../api";

const ReportsModule = () => {
  return {
    ListTransaction: function (data) {
      const { start_date, end_date, page, per_page, query, status, product, transactionType } =
        data;
      return api.get(
        `identity/agent/accessbank/report/transactions/downline?startDate=${start_date}&endDate=${end_date}&currentPage=${page}&pageSize=${per_page}
        &SearchText=${query}&transactionType=${transactionType}&product=${product ? product : ""}&status=${status ? status : ""}`
      );
    },

    AgentListTransaction: function (data) {
      return api.post("wallet/Wallet/downline/wallettransactions", data);
    },

    TransactionDetails: function (data) {
      return api.post(`/Admin/reports/airtime/${data?.ref}`, data);
    },

    MainWallet: function (data) {
      return api.post("wallet/Wallet/walletmovements", data);
    },

    CommissionWalletReport: function (data) {
      return api.get(
        `/wallet/Wallet/commissionmovements?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&keyword=${data.keyword}&ServiceType=${data.ServiceType}&StartDate=${data.startDate}&EndDate=${data.endDate}`
      );
    },

    AirtimeReports: function (data) {
      return api.get(
        // `/wallet/Wallet/commissionmovements?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&keyword=${data.keyword}&ServiceType=${data.ServiceType}&StartDate=${data.startDate}&EndDate=${data.endDate}`
        `airtime/Airtime/airtimetransactions?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&SearchText=${data.keyword}&Network=${data.network}&StartDate=${data.startDate}&EndDate=${data.endDate}&TransactionStatus=${data.transactionStatus}&includeDownline=true`
        // ?SearchText=""&TransactionStatus=2&Network=2&PageSize=10&CurrentPage=1&CurrentPageIndex=1&StartDate=2021-01-01&EndDate=2022-09-30
        // `
      );
    },

    BillsReports: function (data) {
      return api.get(
        `bills/BillerService/billstransactions?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&SearchText=${data.keyword}&Network=${data.network}&StartDate=${data.startDate}&EndDate=${data.endDate}&TransactionStatus=${data.transactionStatus}&includeDownline=true&serviceType=${data.serviceType}`
      );
    },

    DataReports: function (data) {
      return api.get(
        `data/Service/datatransactions?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&SearchText=${data.keyword}&Network=${data.network}&StartDate=${data.startDate}&EndDate=${data.endDate}&TransactionStatus=${data.transactionStatus}&includeDownline=true`
      );
    },

    AirtimeReportsWithoutDownlines: function (data) {
      return api.get(
        // `/wallet/Wallet/commissionmovements?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&keyword=${data.keyword}&ServiceType=${data.ServiceType}&StartDate=${data.startDate}&EndDate=${data.endDate}`
        `airtime/Airtime/airtimetransactions?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&SearchText=${data.keyword}&Network=${data.network}&StartDate=${data.startDate}&EndDate=${data.endDate}&TransactionStatus=${data.transactionStatus}`
        // ?SearchText=""&TransactionStatus=2&Network=2&PageSize=10&CurrentPage=1&CurrentPageIndex=1&StartDate=2021-01-01&EndDate=2022-09-30
        // `
      );
    },

    BillsReportsWithoutDownlines: function (data) {
      const {
        pageSize,
        currentPage,
        keyword,
        network,
        startDate,
        endDate,
        transactionStatus,
        serviceType,
      } = data;
      const params = {
        PageSize: pageSize,
        CurrentPage: currentPage,
        SearchText: keyword,
        Network: network,
        StartDate: startDate,
        EndDate: endDate,
        TransactionStatus: transactionStatus,
        serviceType: serviceType,
      };

      if (network) {
        params.Network = network;
      }

      if (transactionStatus) {
        params.TransactionStatus = transactionStatus;
      }

      return api.get(`bills/BillerService/billstransactions`, { params });
    },

    DataReportsWithoutDownlines: function (data) {
      return api.get(
        `data/Service/datatransactions?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&SearchText=${data.keyword}&Network=${data.network}&StartDate=${data.startDate}&EndDate=${data.endDate}&TransactionStatus=${data.transactionStatus}`
      );
    },

    CusstomerListing: function (data) {
      return api.get(
        `identity/Agent/customerlisting?AgentRoleId=${data.agentrole}&PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&StartDate=${data.startDate}&EndDate=${data.endDate}`
      );
    },

    SingleCustomerListing: function (data) {
      return api.get(`identity/Agent/downline/details?walletId=${data}`);
    },

    TransactionSummary: function (data) {
      const { startDate, endDate, service_code, currentPage, pageSize } = data;
      return api.get(
        `wallet/Wallet/report/service/summary?startDate=${startDate}&endDate=${endDate}&service_code=${service_code}&CurrentPage=${currentPage}&PageSize=${pageSize}`
      );
    },
  };
};

export const ReportsService = {
  reportsModule: ReportsModule,
};
