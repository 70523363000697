import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { OtherReportsService } from "./OtherReportsService";

const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

export const WIPAYTransactionRequest = createAsyncThunk(
  "ls/WIPAYTransactionRequest",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

   
    try {
      const response =
        await OtherReportsService.otherReportsModule().getWIPAYTransReq(value);
      scrollToTop();
      return response?.data;
    } catch (error) {
      // exitReponse(error)
      return error?.response;
    }
  }
);
export const WIPAYTransactionNotification = createAsyncThunk(
  "ls/WIPAYTransactionNotification",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
   
    try {
      const response =
        await OtherReportsService.otherReportsModule().getWIPAYTransNot(value);
      scrollToTop();
      return response?.data;
    } catch (error) {
      // exitReponse(error);
      return error?.response;
    }
  }
);

export const WIPAYTransactionSplit = createAsyncThunk(
  "ls/WIPAYTransactionSplit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
   
    try {
      const response =
        await OtherReportsService.otherReportsModule().getWIPAYTransSplit(
          value
        );
      scrollToTop();
      return response?.data;
    } catch (error) {
      // exitReponse(error);
      return error?.response;
    }
  }
);

export const WIPAYAggregatedSplit = createAsyncThunk(
  "ls/WIPAYAggregatedSplit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
   
    try {
      const response =
        // await OtherReportsService.otherReportsModule().getWIPAYAggregatedSplit
        await OtherReportsService.otherReportsModule().getWIPAYAggregatedSplit(
          value
        );
      scrollToTop();
      return response?.data;
    } catch (error) {
      // exitReponse(error);
      return error?.response;
    }
  }
);

export const CardNetworkPerformance = createAsyncThunk(
  "ls/CardNetworkPerformance",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
   
    try {
      const response =
        await OtherReportsService.otherReportsModule().CardNetworkPerformance(
          value
        );
      scrollToTop();
      return response?.data;
    } catch (error) {
      // exitReponse(error);
      return error?.response;
    }
  }
);

export const ReportsSlice = createSlice({
  name: "Reports",
  initialState: "",
  extraReducers: {
    [WIPAYTransactionRequest.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [WIPAYTransactionRequest.fulfilled]: (state, action) => {
      return {
        ...state,
        wipay_transaction_request: action.payload,
        loading: false,
      };
    },
    [WIPAYTransactionRequest.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [WIPAYTransactionNotification.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [WIPAYTransactionNotification.fulfilled]: (state, action) => {
      return {
        ...state,
        wipay_transaction_notification: action.payload,
        loading: false,
      };
    },
    [WIPAYTransactionNotification.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [WIPAYTransactionSplit.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [WIPAYTransactionSplit.fulfilled]: (state, action) => {
      return {
        ...state,
        wipay_transaction_split: action.payload,
        loading: false,
      };
    },
    [WIPAYTransactionSplit.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [WIPAYAggregatedSplit.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [WIPAYAggregatedSplit.fulfilled]: (state, action) => {
      return {
        ...state,
        wipay_aggregated_split: action.payload,
        loading: false,
      };
    },
    [WIPAYAggregatedSplit.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [CardNetworkPerformance.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CardNetworkPerformance.fulfilled]: (state, action) => {
      return {
        ...state,
        card_network_performance: action.payload,
        loading: false,
      };
    },
    [CardNetworkPerformance.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export default ReportsSlice.reducer;
