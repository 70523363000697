import axios from "axios";
import api from "../api";

const authApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

const AuthModule = () => {
  return {
    Login: function (data) {
      return authApi.post("identity/Agent/login", data);
    },

    ResetPassword: function (data) {
      // return authApi.post("identity/Agent/setnewpassword", data);
      return authApi.post("identity/Agent/completeforgotpassword", data);
    },

    CurrentUser: function (data) {
      return api.get("identity/Agent/sessiondetails");
    },

    ServiceToday: function (data) {
      return api.get(`wallet/Wallet/report/service/today`);
    },

    // /Agent/accessbank/superdealer/transaction-metrics
    AccessTransactionMetrics: function (data) {
      return api.get(`identity/Agent/accessbank/superdealer/transaction-metrics?frequency=${data}`);
    },

    ServiceWeek: function (data) {
      return api.get("wallet/Wallet/report/service/this_week");
    },

    AgentDetails: function (data) {
      const { page, limit } = data;
      return api.get(
        `/transactions/report/superdealer/performance/top-resselers?customer_id=501&service_codes=100,101,102,103,106,105&per_page=${limit}&page=${page}`
      );
    },

    transactionVolumeAndCount: function (data) {
      return api.get("identity/Agent/accessbank/superdealer/transaction/volume");
    },

    superdealerActiveAgents: function (data) {
      return api.get("identity/Agent/accessbank/superdealer/active-agents");
    },
  };
};

export const AuthService = {
  auth: AuthModule,
};
